import { Col, Row } from '@advanza/grid'
import Modal from '@advanza/modal'
import { Button, Divider, PreIcon } from '@advanza/ui'
import A from 'components/ui/A'
import { usePublicReviews } from 'hooks/reviewsHooks'
import { Fragment, useRef, useState } from 'react'
import style from './publicReviews.module.css'

const PublicReviews = ({ providerId }: { providerId: number }) => {
    const urlRef = useRef<HTMLSpanElement>(null)
    const { url, hasPublicReviews, onGenerate, isGenerating, isGenerated } =
        usePublicReviews(providerId)
    const [open, setOpen] = useState(false)

    const onRenew = () => {
        onGenerate()
        setOpen(false)
    }

    return (
        <div className={style.publicReviews}>
            <Divider s />
            {isGenerated && (
                <Fragment>
                    <div>New url generated!</div>
                    <Divider s />
                </Fragment>
            )}
            {hasPublicReviews && url ? (
                <Fragment>
                    <A className={style.url} external href={url} target="_blank">
                        <span ref={urlRef}>{url}</span>
                    </A>
                    <Divider m />
                    <small className={style.renew} onClick={() => setOpen(true)}>
                        Generate new url
                    </small>
                </Fragment>
            ) : url ? (
                <Row end="xs" middle="xs">
                    <Col xs={12}>
                        <Button name="primary" disabled={isGenerating} onClick={onGenerate}>
                            {isGenerating ? 'Generating..' : 'Generate url'}
                        </Button>
                    </Col>
                </Row>
            ) : (
                <PreIcon icon="warning" red fontSize={16}>
                    <span style={{ color: '#f42435' }}>No active filters</span>
                </PreIcon>
            )}
            <Modal
                classNames="fullScreen"
                closeOnOutsideClick
                close={() => setOpen(false)}
                maxWidth={600}
                open={open}>
                <div>
                    <h3>Renew public reviews url</h3>
                    <Divider m />
                    <div>
                        Only renew the url if old url is not secure anymore.
                        <br />
                        The old url cannot be used anymore after renewal. Continue?
                    </div>
                    <Divider l />
                    <Row end="xs" middle="xs">
                        <Col x>
                            <Button disabled={isGenerating} onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                        </Col>
                        <Col x>
                            <Button disabled={isGenerating} onClick={onRenew}>
                                {isGenerating ? 'Generating..' : 'Yes, generate new url'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default PublicReviews
