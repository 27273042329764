import React from 'react'
import * as ReactDOM from 'react-dom'
import style from './../linearLoader.module.css'

class LinearLoader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    canUseDOM() {
        return !!(typeof window !== 'undefined' && window.document && window.document.createElement)
    }

    componentDidMount() {
        this.setState({ mounted: true })
    }

    render() {
        const { fixed, absolute, className } = this.props
        if ((!this.canUseDOM() || !this.state.mounted) && fixed) {
            return <div></div>
        }
        const loader = (
            <div
                className={[
                    style.linearProgress,
                    fixed ? style.fixed : absolute ? style.absolute : '',
                    className || '',
                ].join(' ')}>
                <div className={[style.bar, style.bar1].join(' ')} />
                <div className={[style.bar, style.bar2].join(' ')} />
            </div>
        )

        if (fixed) {
            return ReactDOM.createPortal(loader, document.getElementById('portal_1'))
        } else {
            return loader
        }
    }
}

export default LinearLoader
