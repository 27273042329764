import style from './../subTitle.module.css'

const SubTitle = ({ children, bold = false, ...rest }) => {
    return (
        <div
            {...rest}
            className={[style.subTitle, bold ? style.bold : '', rest.className].join(' ')}>
            {children}
        </div>
    )
}

export default SubTitle
