import { Button, PreIcon } from '@advanza/ui'
import A from 'components/ui/A'
import { Provider } from 'interfaces/Providers'
import { getCompanyBaseUrlOffice } from 'misc/companyBaseUrlOffice'
import { useEffect, useState } from 'react'

interface Props {
    provider: Provider
}

const ProfileUrlLink = ({ provider }: Props) => {
    const [error, setError] = useState<string | false>(false)
    const baseUrl = getCompanyBaseUrlOffice(provider.country_code)

    useEffect(() => {
        if (!provider.profile_url) {
            setError('Profile url field is empty')
        } else if (!provider.providerUrl) {
            setError('No active filters')
        } else if (!provider.providerUrlVisible) {
            setError('Service is not live yet')
        } else {
            setError(false)
        }
    }, [provider])

    return error ? (
        <PreIcon icon="warning" red fontSize={16}>
            <span style={{ color: '#f42435' }}>{error}</span>
        </PreIcon>
    ) : (
        <A external href={`${baseUrl}${provider.providerUrl}`} target="_blank">
            <Button name="textReadMore">To profile</Button>
        </A>
    )
}

export default ProfileUrlLink
