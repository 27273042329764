import { ErrorBoundary } from '@advanza/ui'
import NiceModal from '@ebay/nice-modal-react'
import { store } from 'app/store'
import AdSheetsPage from 'components/adSheets/AdSheetsPage'
import AnalyticsPage from 'components/analytics/AnalyticsPage'
import ApisPage from 'components/apis/ApisPage'
import BansPage from 'components/bans/BansPage'
import DebtorsPage from 'components/billing/DebtorsPage'
import ExactImportPage from 'components/billing/ExactImportPage'
import InvoicesPage from 'components/billing/InvoicesPage'
import OpenChargesPage from 'components/billing/OpenChargesPage'
import ChannableFeedPage from 'components/channableFeed/ChannableFeedPage'
import CountryPage from 'components/country/CountryPage'
import QueuePage from 'components/dev/QueuePage'
import EventFilter from 'components/events/EventFilter'
import EventList from 'components/events/EventList'
import ExpectationSettingsPage from 'components/expectationsettings/ExpectationSettingsPage'
import GeoTargetingPage from 'components/geoTargeting/GeoTargetingPage'
import ExpertiseKeywordsPage from 'components/keywords/ExpertiseKeywordsPage'
import LeadEstimatorPage from 'components/leadEstimator/LeadEstimatorPage'
import LicencesPage from 'components/licences/LicencesPage'
import LicencesUpdatersPage from 'components/licencesUpdaters/LicencesUpdatersPage'
import LimitReachedPage from 'components/limitReached/LimitReachedPage'
import ErIsIetsMisGegaan from 'components/misc/ErIsIetsMisGegaan'
import MatchingDebugger from 'components/misc/MatchingDebugger'
import MiscPage from 'components/misc/MiscPage'
import ActivePausesPage from 'components/pauses/ActivePausesPage'
import ProviderEditViewPage from 'components/providers/ProviderEditViewPage'
import RegionsPage from 'components/regions/RegionsPage'
import AggregatedReviewsPage from 'components/reviews/AggregatedReviewsPage'
import HomePageTestimonialsPage from 'components/reviews/HomePageTestimonialsPage'
import SeoPage from 'components/seo/SeoPage'
import SEOServicesPlacesContentsPage from 'components/seo/SEOServicesPlacesContentsPage'
import QuestionnairesPage from 'components/services/QuestionnairesPage'
import TestPage from 'components/TestPage'
import Page from 'components/ui/Page'
import SendgridMailsPage from 'mails/SendgridMailsPage'
import changeFavicon from 'misc/changeFavicon'
import Querystring from 'querystring'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { Switch } from 'react-router-dom'
import AdminUsersPage from '../components/adminUsers/AdminUsersPage'
import CancellationsPage from '../components/cancellations/CancellationsPage'
import ClosedLoopEmailsPage from '../components/closedLoop/ClosedLoopEmailsPage'
import ClosedLoopRequestsPage from '../components/closedLoop/ClosedLoopRequestsPage'
import DemandUsersPage from '../components/demandUsers/DemandUsersPage'
import EventsPage from '../components/events/EventsPage'
import EventViewPage from '../components/events/EventViewPage'
import LabelsPage from '../components/labels/LabelsPage'
import LoginPage from '../components/LoginPage'
import MatchesPage from '../components/matches/MatchesPage'
import MatchViewPage from '../components/matches/MatchViewPage'
import ProDashboardPage from '../components/proDashboard/ProDashboardPage'
import ProfileBuilderWizardPage from '../components/profileBuilderWizard/ProfileBuilderWizardPage'
import ProvidersBuildersPage from '../components/profileBuilderWizard/ProvidersBuildersPage'
import ProfileReportPage from '../components/profileReport/ProfileReportPage'
import ProvidersPage from '../components/providers/ProvidersPage'
import ProviderViewPage from '../components/providers/ProviderViewPage'
import ProvidersBansPage from '../components/providersBans/ProvidersBansPage'
import ReclaimsPage from '../components/reclaims/ReclaimsPage'
import RequestsPage from '../components/requests/RequestsPage'
import RequestViewPage from '../components/requests/RequestViewPage'
import { Rout as Route } from '../components/routes/Rout'
import SalesLeadsPage from '../components/salesLeads/SalesLeadsPage'
import SalesLeadViewPage from '../components/salesLeads/SalesLeadViewPage'
import ServiceInfoPage from '../components/serviceInfo/ServiceInfoPage'
import ServicesPage from '../components/services/ServicesPage'
import ServiceViewPage from '../components/services/ServiceViewPage'
import StatusesPage from '../components/statuses/StatusesPage'
import SubscriptionPage from '../components/subscriptions/SubscriptionPage'
import ComingSoon from '../components/ui/ComingSoon'

const messages = require('../locale/en')
window.previousRoute = window.location.pathname
const formats = {
    time: {
        short: {
            hour12: false,
            hour: '2-digit',
            minute: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
    },
}

class Root extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.previousRoute = prevProps.location.pathname
        }
    }

    componentDidMount() {
        if (window.location.hostname !== 'office.trustoo.nl') {
            changeFavicon('/apps/office/test/favicon.ico')
        }
    }

    render() {
        return (
            <div className={window.isMobile ? 'mobile' : 'desktop'}>
                <Provider store={store}>
                    <NiceModal.Provider>
                        <IntlProvider formats={formats} locale="en" messages={messages}>
                            <Switch>
                                {/* Sales */}
                                <Route exact path="/" component={ComingSoon} />
                                <Route exact path="/test" component={TestPage} />
                                <Route exact path="/sales" component={ComingSoon} />
                                <Route exact path="/sales/leads" component={SalesLeadsPage} />
                                <Route
                                    exact
                                    path="/sales/leads/lead/:id"
                                    component={SalesLeadViewPage}
                                />
                                <Route exact path="/sales/account" component={ComingSoon} />
                                <Route
                                    exact
                                    path="/sales/site-info/:id?"
                                    component={ServiceInfoPage}
                                />
                                <Route exact path="/sales/lukewarm" component={ComingSoon} />
                                <Route exact path="/sales/opportunities" component={ComingSoon} />
                                <Route
                                    exact
                                    path="/sales/lead-estimator/:serviceId?/:subscriptionId?"
                                    component={LeadEstimatorPage}
                                />

                                {/* Client service */}
                                <Route exact path="/service/requests" component={RequestsPage} />
                                <Route
                                    exact
                                    path="/service/requests/request/:id"
                                    component={RequestViewPage}
                                />

                                <Route exact path="/service/matches" component={MatchesPage} />
                                <Route
                                    exact
                                    path="/service/matches/match/:id"
                                    component={MatchViewPage}
                                />

                                <Route exact path="/service/events" component={EventsPage} />
                                <Route
                                    exact
                                    path="/service/events/event/:id"
                                    component={EventViewPage}
                                />

                                <Route exact path="/service/analytics" component={AnalyticsPage} />
                                <Route
                                    exact
                                    path="/settings/matching-debugger/"
                                    component={MatchingDebugger}
                                />

                                <Route
                                    exact
                                    path="/service/profile-report"
                                    component={ProfileReportPage}
                                />

                                <Route exact path="/service/providers" component={ProvidersPage} />
                                <Route
                                    exact
                                    path="/service/providers/provider/edit/:id?"
                                    component={ProviderEditViewPage}
                                />
                                <Route
                                    exact
                                    path="/service/providers/provider/:id?/:tab?"
                                    component={ProviderViewPage}
                                />
                                <Route
                                    exact
                                    path="/service/providers/subscription/:id?"
                                    component={SubscriptionPage}
                                />

                                <Route
                                    exact
                                    path="/service/demand-users"
                                    component={DemandUsersPage}
                                />
                                <Route
                                    exact
                                    path="/service/cancellations"
                                    component={CancellationsPage}
                                />
                                <Route
                                    exact
                                    path="/service/limit-reached"
                                    component={LimitReachedPage}
                                />
                                <Route
                                    exact
                                    path="/service/active-pauses"
                                    component={ActivePausesPage}
                                />
                                <Route
                                    exact
                                    path="/service/profile-builder-wizard/:id?/:page?"
                                    render={() => (
                                        <ErrorBoundary
                                            slackOptions={{
                                                emoji: ':male_mage:',
                                                username: 'Profile Builder Wizard',
                                            }}>
                                            <ProfileBuilderWizardPage />
                                        </ErrorBoundary>
                                    )}
                                />
                                <Route
                                    exact
                                    path="/service/providers-builders"
                                    component={ProvidersBuildersPage}
                                />
                                <Route
                                    exact
                                    path="/statistics/closed-loop-emails"
                                    component={ClosedLoopEmailsPage}
                                />
                                <Route
                                    exact
                                    path="/statistics/closed-loop-requests"
                                    component={ClosedLoopRequestsPage}
                                />
                                <Route
                                    exact
                                    path="/statistics/closed-loop-requests/:source/request/:id"
                                    component={RequestViewPage}
                                />

                                <Route exact path="/billing/open" component={OpenChargesPage} />
                                <Route exact path="/billing/invoices" component={InvoicesPage} />
                                <Route exact path="/billing/debtors" component={DebtorsPage} />
                                <Route
                                    exact
                                    path="/billing/exact-export"
                                    component={ExactImportPage}
                                />
                                <Route exact path="/billing/reclaims" component={ReclaimsPage} />
                                <Route
                                    exact
                                    path="/billing/quarantined"
                                    component={ProvidersBansPage}
                                />
                                <Route exact path="/billing/bans" component={BansPage} />

                                <Route exact path="/settings/licences" component={LicencesPage} />
                                <Route
                                    exact
                                    path="/settings/licences-updaters"
                                    component={LicencesUpdatersPage}
                                />
                                <Route exact path="/settings/services" component={ServicesPage} />
                                <Route exact path="/settings/seo" component={SeoPage} />
                                <Route
                                    exact
                                    path="/settings/seo/services-places-contents"
                                    component={SEOServicesPlacesContentsPage}
                                />
                                <Route
                                    exact
                                    path="/settings/expertise-keywords"
                                    component={ExpertiseKeywordsPage}
                                />
                                <Route
                                    exact
                                    path="/settings/services/service/:id"
                                    component={ServiceViewPage}
                                />
                                <Route
                                    exact
                                    path="/settings/services/service/:id/questions"
                                    component={QuestionnairesPage}
                                />
                                <Route exact path="/settings/country" component={CountryPage} />
                                <Route exact path="/settings/regions" component={RegionsPage} />
                                <Route
                                    exact
                                    path="/settings/reviews"
                                    component={HomePageTestimonialsPage}
                                />
                                <Route
                                    exact
                                    path="/settings/review-sources"
                                    component={AggregatedReviewsPage}
                                />
                                <Route exact path="/settings/labels" component={LabelsPage} />
                                <Route exact path="/settings/statuses" component={StatusesPage} />
                                <Route
                                    exact
                                    path="/settings/admin-users"
                                    component={AdminUsersPage}
                                />

                                <Route
                                    exact
                                    path="/settings/pro-dashboard"
                                    component={ProDashboardPage}
                                />

                                <Route exact path="/settings/apis" component={ApisPage} />

                                <Route
                                    exact
                                    path="/ads/channable-feed"
                                    component={ChannableFeedPage}
                                />

                                <Route
                                    exact
                                    path="/ads/expectation-settings"
                                    component={ExpectationSettingsPage}
                                />

                                <Route exact path="/ads/adsheets" component={AdSheetsPage} />

                                <Route
                                    exact
                                    path="/ads/geo-targeting"
                                    component={GeoTargetingPage}
                                />

                                <Route exact path="/sendgrid-mails" component={SendgridMailsPage} />

                                <Route exact path="/login" component={LoginPage} notRestricted />
                                <Route
                                    exact
                                    path="/two-factor-secret"
                                    render={(props) => (
                                        <LoginPage {...props} page="twoFactorSecret" />
                                    )}
                                    notRestricted
                                />
                                <Route
                                    exact
                                    path="/two-factor-one-time-code"
                                    render={(props) => (
                                        <LoginPage {...props} page="twoFactorOneTimeCode" />
                                    )}
                                    notRestricted
                                />
                                <Route
                                    path="/change-password/:token?/:email?"
                                    render={(props) => (
                                        <LoginPage
                                            {...props}
                                            page="changePassword"
                                            email={props.match.params.email}
                                            token={props.match.params.token}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/logout"
                                    render={() => <LoginPage page="logout" />}
                                    notRestricted
                                />
                                <Route exact path="/misc" component={MiscPage} />
                                <Route
                                    exact
                                    path="/er-is-iets-misgegaan"
                                    component={ErIsIetsMisGegaan}
                                />
                                <Route
                                    path="/events/event-list"
                                    render={() => {
                                        const query = Querystring.parse(
                                            window.location.search.substr(1)
                                        )

                                        return (
                                            <Page>
                                                <EventFilter
                                                    filterId="eventList"
                                                    defaultOptions={{
                                                        ...query,
                                                    }}
                                                />
                                                <EventList filterId="eventList" />
                                            </Page>
                                        )
                                    }}
                                />
                                <Route path="/dev/queue" component={QueuePage} />
                                <Route component={ComingSoon} />
                            </Switch>
                        </IntlProvider>
                    </NiceModal.Provider>
                </Provider>
                <canvas
                    style={{ position: 'fixed', opacity: 0, zIndex: -111 }}
                    id="svgToPngCanvas"></canvas>
            </div>
        )
    }
}

export default Root
