import { call } from '@advanza/api'
import { useList } from 'hooks/listHooks'
import { useCurrentCountry } from 'hooks/miscHooks'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import invoicesSchema from 'schemes/InvoicesSchema'

function setIsGenerating(isGenerating) {
    return (dispatch) =>
        dispatch({
            type: 'SET_INVOICES',
            action: {
                openCharges: {
                    isGenerating: { $set: isGenerating },
                },
            },
        })
}

function setIsFetching(setIsFetching) {
    return (dispatch) =>
        dispatch({
            type: 'SET_INVOICES',
            action: {
                openCharges: {
                    isFetching: { $set: setIsFetching },
                },
            },
        })
}

function fetchOpenCharges(aboutMonth, countries, dates) {
    return async function (dispatch, getState) {
        if (getState().invoices.openCharges.isFetching) {
            return
        }

        dispatch(setIsFetching(true))
        const query = {
            countries,
            startDate: dates && dates.startDate,
            endDate: dates && dates.endDate,
        }

        try {
            const response = await call('office/invoices/get-open/' + (aboutMonth || ''), { query })
            dispatch({
                type: 'SET_INVOICES',
                action: {
                    openCharges: {
                        aboutMonth: { $set: aboutMonth },
                        isFetching: { $set: false },
                        dates: { $set: dates },
                        directDebit: { $set: response.directDebit },
                        manualTransfer: { $set: response.manualTransfer },
                        noData: { $set: response.noData },
                    },
                },
            })
        } finally {
            dispatch(setIsFetching(false))
        }
    }
}

function generateInvoices(dispatch, aboutMonth, countries, dates) {
    dispatch(setIsGenerating(true))
    const payload = {
        countries,
        startDate: dates && dates.startDate,
        endDate: dates && dates.endDate,
    }
    return call('office/invoices/generate-invoices/' + (aboutMonth || ''), {
        method: 'post',
        payload,
    })
        .then((response) => {
            dispatch({
                type: 'SET_INVOICES',
                action: {
                    openCharges: {
                        isGenerating: { $set: false },
                        errors: { $set: response.errors },
                    },
                },
            })
            dispatch(fetchOpenCharges(aboutMonth, countries, dates))
        })
        .finally(() => dispatch(setIsGenerating(false)))
}

export function useOpenCharges() {
    // 'e.g. 2019-05
    const dispatch = useDispatch()
    const { countries } = useSelector((state) => state.countries)
    const { errors: responseError, setErrors: setResponseErrors } = useErrors()
    const {
        isFetching,
        dates = {},
        directDebit,
        noData,
        aboutMonth,
        manualTransfer,
        isGenerating,
        errors,
    } = useSelector((state) => state.invoices.openCharges)

    const setMonth = (aboutMonth) => {
        dispatch({
            type: 'SET_INVOICES',
            action: {
                openCharges: { aboutMonth: { $set: aboutMonth } },
            },
        })
    }
    const setDates = (dates) => {
        dispatch({
            type: 'SET_INVOICES',
            action: {
                openCharges: { dates: { $set: dates } },
            },
        })
    }
    const countriesStr = countries.join(',')
    const datesStr = dates.startDate + '|' + dates.endDate

    useEffect(() => {
        dispatch(fetchOpenCharges(aboutMonth, countries, dates))
    }, [aboutMonth, countriesStr, datesStr])

    const onGenerateInvoices = () => {
        setResponseErrors(false)
        if (!isGenerating) {
            generateInvoices(dispatch, aboutMonth, countries, dates).then(
                () => {
                    dispatch({ type: 'INVALIDATE_INVOICES' })
                },
                (response) => {
                    setResponseErrors(response.error)
                }
            )
        }
    }
    const clearErrors = () => {
        dispatch({
            type: 'SET_INVOICES',
            action: {
                openCharges: { errors: { $set: [] } },
            },
        })
    }

    return {
        directDebit,
        manualTransfer,
        noData,
        isFetching,
        onGenerateInvoices,
        setMonth,
        aboutMonth,
        dates,
        setDates,
        isGenerating,
        responseError,
        clearErrors,
        errors,
    }
}

export function useInvoice(invoiceNr) {
    const { invoices, charges } = useSelector((state) => state.invoices.entities)
    const invoice = invoices[invoiceNr]

    return { invoice, charges: invoice.charges.map((id) => charges[id]) }
}

export function useInvoicesList(filterId, defaultOptions) {
    return useList(
        filterId,
        {
            url: 'office/invoices/get-list',
            schema: invoicesSchema,
            reducerKey: 'invoices',
        },
        defaultOptions
    )
}

export function useInvoiceActions(filterId) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [date, setDate] = useState(null)
    const { countries } = useSelector((state) => state.countries)
    const [response, setResponse] = useState(false)
    const { selection = [] } = useSelector((state) => state.invoices.filters[filterId]) || {}

    const setTransactionFulfilled = () => {
        setIsLoading(true)
        return call('office/invoices/set-transaction-fulfilled', {
            payload: {
                ids: selection,
                date,
            },
        })
            .then((response) => {
                dispatch({ type: 'INVALIDATE_INVOICES', keepSelection: true })
                setResponse(JSON.stringify(response))
            })
            .finally(() => setIsLoading(false))
    }

    const setTransactionUnfulfilled = () => {
        setIsLoading(true)
        return call('office/invoices/set-transaction-unfulfilled', {
            payload: {
                ids: selection,
                date,
            },
        })
            .then((response) => {
                dispatch({ type: 'INVALIDATE_INVOICES', keepSelection: true })
                setResponse(JSON.stringify(response))
            })
            .finally(() => setIsLoading(false))
    }

    const addToDebtCollection = (undo) => {
        setIsLoading(true)
        return call('office/invoices/add-to-debt-collection', {
            payload: { ids: selection, date, undo },
        })
            .then(() => {
                dispatch({ type: 'INVALIDATE_INVOICES', keepSelection: true })
            })
            .finally(() => setIsLoading(false))
    }

    const markLost = (undo) => {
        setIsLoading(true)
        return call('office/invoices/mark-lost', {
            payload: { ids: selection, date, undo },
        })
            .then(() => {
                dispatch({ type: 'INVALIDATE_INVOICES', keepSelection: true })
            })
            .finally(() => setIsLoading(false))
    }

    const clearResponse = () => setResponse(null)

    async function createTransactions() {
        const result = {
            success: [],
            errors: [],
        }
        if (isLoading) {
            return Promise.resolve()
        }
        setIsLoading(true)
        for (const id of selection) {
            dispatch({ type: 'SET_LOADING_INVOICE', id, isLoading: true })
            await call('office/invoices/create-transactions', {
                payload: { ids: [id], countries },
            })
                .then(
                    (res) => {
                        if (res.successes.length === 1) {
                            dispatch({ type: 'SET_INVOICE_ACTION_STATUS', id, status: 'success' })
                            result.success.push({
                                id,
                                success: res.successes[0],
                            })
                        } else {
                            dispatch({ type: 'SET_INVOICE_ACTION_STATUS', id, status: 'error' })
                            result.errors.push({
                                id,
                                err: res.errors[0],
                            })
                        }
                    },
                    () => {
                        result.errors.push(id)
                    }
                )
                .finally(() => {
                    dispatch({ type: 'SET_LOADING_INVOICE', id, isLoading: false })
                })
        }
        dispatch({ type: 'INVALIDATE_INVOICES' })
        setResponse(JSON.stringify(result?.errors, null, 2))
        setIsLoading(false)
    }

    const createSecondPayment = () => {
        if (isLoading) {
            return Promise.resolve()
        }

        setIsLoading(true)
        return call('office/invoices/create-second-payments', { payload: { ids: selection } })
            .then((response) => {
                dispatch({ type: 'INVALIDATE_INVOICES', keepSelection: true })
                setResponse(JSON.stringify(response, null, 2))
            })
            .catch((err) => {
                setResponse(JSON.stringify(err || {}, null, 2))
            })
            .finally(() => setIsLoading(false))
    }

    const sendToNotify = () => {
        setIsLoading(true)
        return call('office/invoices/send-to-notify', { payload: { ids: selection } })
            .then((response) => {
                dispatch({ type: 'INVALIDATE_INVOICES', keepSelection: true })
                setResponse(JSON.stringify(response, null, 2))
            })
            .catch((err) => {
                setResponse(JSON.stringify(err || {}, null, 2))
            })
            .finally(() => setIsLoading(false))
    }

    const cancelTransactions = () => {
        setIsLoading(true)
        return call('office/invoices/cancel-transactions', {
            payload: { ids: selection, countries },
        })
            .then((response) => {
                dispatch({ type: 'INVALIDATE_INVOICES', keepSelection: true })
                setResponse(
                    JSON.stringify({ success: response.successes, errors: response.errors })
                )
            })
            .finally(() => setIsLoading(false))
    }

    const disableOverdueInvoice = (unset = false) => {
        setIsLoading(true)
        return call('office/invoices/set-disabled-overdue-invoice/' + (unset ? 'unset' : ''), {
            payload: { ids: selection, countries, unset },
        })
            .then((response) => {
                dispatch({ type: 'INVALIDATE_INVOICES', keepSelection: true })
                setResponse(JSON.stringify(response))
            })
            .finally(() => setIsLoading(false))
    }

    const createWIKLetterLink = () => {
        return (
            '//' +
            window.location.hostname +
            `/api/office/invoices/download-wik-letter/?invoiceIds[]=${selection.join(
                '&invoiceIds[]='
            )}`
        )
    }

    return {
        addToDebtCollection,
        sendToNotify,
        clearResponse,
        createTransactions,
        cancelTransactions,
        createSecondPayment,
        disableOverdueInvoice,
        date,
        setDate,
        setTransactionFulfilled,
        setTransactionUnfulfilled,
        createWIKLetterLink,
        isLoading,
        response,
        markLost,
    }
}

export function useErrors() {
    const [errors, setErrors] = useState(false)
    const clearErrors = () => setErrors(false)

    return { errors, clearErrors, setErrors }
}

export function useInvoiceExport() {
    const [isExporting, setIsExporting] = useState(false)
    const [invoices, setInvoices] = useState(false)
    const [relations, setRelations] = useState(false)
    const [mandates, setMandates] = useState(false)
    const { errors, clearErrors, setErrors } = useErrors()
    const countryCode = useCurrentCountry()
    const exportInvoices = () => {
        setIsExporting('invoices')
        call('office/invoices/export-exact-invoices')
            .then((response) => {
                setInvoices(response)
                setIsExporting(false)
            }, setErrors)
            .finally(() => setIsExporting(false))
    }
    const exportRelations = () => {
        setIsExporting('relations')
        call('office/invoices/export-exact-relations')
            .then((response) => {
                setRelations(response)
                setIsExporting(false)
            }, setErrors)
            .finally(() => setIsExporting(false))
    }
    const exportMandates = () => {
        setIsExporting('mandates')
        call('office/invoices/export-exact-mandates')
            .then((response) => {
                setMandates(response)
                setIsExporting(false)
            }, setErrors)
            .finally(() => setIsExporting(false))
    }

    const loadPreviousMandateBatch = () => {
        setIsExporting('mandates')
        call('office/invoices/mandates-previous-batches?country=' + countryCode)
            .then((response) => {
                setMandates({ ...(mandates || {}), prevBatches: response.batches })
                setIsExporting(false)
            }, setErrors)
            .finally(() => setIsExporting(false))
    }
    const loadPreviousRelationsBatch = () => {
        setIsExporting('relations')
        call('office/invoices/relations-previous-batches?country=' + countryCode)
            .then((response) => {
                setRelations({ ...(relations || {}), prevBatches: response.batches })
                setIsExporting(false)
            }, setErrors)
            .finally(() => setIsExporting(false))
    }
    const loadPreviousInvoicesBatch = () => {
        setIsExporting('invoices')
        call('office/invoices/invoices-previous-batches?country=' + countryCode)
            .then((response) => {
                setInvoices({ ...(invoices || {}), prevBatches: response.batches })
                setIsExporting(false)
            }, setErrors)
            .finally(() => setIsExporting(false))
    }

    return {
        isExporting,
        relations,
        invoices,
        clearErrors,
        mandates,
        errors,
        exportRelations,
        exportInvoices,
        exportMandates,
        loadPreviousInvoicesBatch,
        loadPreviousMandateBatch,
        loadPreviousRelationsBatch,
    }
}
