import { createReducer, getUpdater } from '@advanza/advanza_generic'
import {
    CHANGE_LEAD_SETTING,
    PROVIDERS_CHANGE_FILTER,
    PROVIDERS_INVALIDATE,
    PROVIDERS_RECEIVE_LIST,
    PROVIDERS_RECEIVE_SINGLE,
    PROVIDERS_REQUEST,
} from '../actions/providers'
import {
    changeEntity,
    changeFilter,
    invalidateFilters,
    receiveList,
    selectItems,
} from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [PROVIDERS_RECEIVE_LIST]: receiveList,
    [PROVIDERS_CHANGE_FILTER]: changeFilter,
    CHANGE_ENTITY_PROVIDERS: changeEntity,
    PROVIDERS_SELECT_ITEMS: selectItems,
    INVALIDATE_PROVIDERS: invalidateFilters,
    [PROVIDERS_REQUEST]: (state) =>
        update(state, {
            isFetching: { $set: true },
        }),
    [CHANGE_LEAD_SETTING]: (state, action) =>
        update(state, {
            entities: {
                leadSettings: { $merge: { [action.setting.setting_id]: action.setting } },
            },
        }),
    [PROVIDERS_RECEIVE_SINGLE]: (state, action) =>
        update(state, {
            isFetching: { $set: false },
            didInvalidate: { $set: false },
            entities: { $deepMerge: action.entities },
        }),
    [PROVIDERS_INVALIDATE]: (state, action) =>
        update(state, {
            didInvalidate: { $set: true },
        }),
    FRANCHISES_RECEIVE: (state, action) =>
        update(state, {
            franchises: { $set: action.response.franchises },
        }),
    FRANCHISES_ADD: (state, action) =>
        state.franchises
            ? update(state, {
                  franchises: { $push: action.franchises },
              })
            : state,
    PROFILE_BUILDER_WIZARD_CHANGE_SELECTION: (state, action) => {
        const { wizardSelection = {} } = state.entities.providers[action.providerId]
        const selection = wizardSelection[action.pageKey] || {}

        return update(state, {
            entities: {
                providers: {
                    [action.providerId]: {
                        wizardSelection: {
                            $set: {
                                ...wizardSelection,
                                [action.pageKey]: { ...selection, ...action.newSelection },
                            },
                        },
                    },
                },
            },
        })
    },
    CALENDLY_OPEN_MODAL: (state, action) =>
        update(state, {
            calendly: {
                isLoaded: { $set: true },
                providerId: { $set: action.providerId },
                calendlyEventId: { $set: action.calendlyEventId },
            },
        }),
    CALENDLY_CHECK_UPDATES: (state) =>
        update(state, {
            calendly: {
                checkForUpdates: { $set: true },
            },
        }),
    CALENDLY_UPDATES_COMPLETED: (state) =>
        update(state, {
            calendly: {
                checkForUpdates: { $set: false },
            },
        }),
    PLATFORMS_TRIGGER_REQUEST: (state) =>
        update(state, {
            platforms: {
                hasFetchedPlatforms: { $set: false },
            },
        }),
    PLATFORMS_REQUEST: (state) =>
        update(state, {
            platforms: {
                isFetchingPlatforms: { $set: true },
            },
        }),
    PLATFORMS_RECEIVE: (state, action) =>
        update(state, {
            platforms: {
                isFetchingPlatforms: { $set: false },
                hasFetchedPlatforms: { $set: true },
                platforms: { $set: action.platforms || state.platforms.platforms },
            },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    entities: {},
    result: {},
    counts: {},
    filters: {},
    calendly: {
        isLoaded: false,
        providerId: null,
        calendlyEventId: null,
        checkForUpdates: false,
    },
    platforms: {
        isFetchingPlatforms: false,
        hasFetchedPlatforms: false,
        platforms: {},
    },
}

export default createReducer(initialState, handlers)
