import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import Icon from '../Icon'
import css from './../maxLines.module.css'

class MaxLines extends React.Component {
    constructor(props) {
        super(props)
        this.hasOverflow = this.hasOverflow.bind(this)
        this.onShowMore = this.onShowMore.bind(this)
        this.calcLineHeight = this.calcLineHeight.bind(this)
        this.onShowLess = this.onShowLess.bind(this)
        this.lineHeight = props.lineHeight || 21
        this.state = {
            showMore: false,
        }
    }

    componentDidMount() {
        this.props.forceUpdate && this.forceUpdate()
    }

    hasOverflow() {
        if (!this.el) {
            return true
        }

        return this.el.scrollHeight > this.el.clientHeight
    }

    calcLineHeight() {
        if (!this.el) {
            return
        }
        this.el.style.overflow = 'scroll'
        const innerHTML = this.el.innerHTML
        this.el.innerHTML = ''
        const scrollHeight = this.el.scrollHeight
        this.el.innerHTML = '<br />'
        const lineHeight = this.el.scrollHeight - scrollHeight
        this.el.innerHTML = innerHTML
        this.el.style.overflow = this.state.showMore ? 'visible' : 'hidden'

        this.lineHeight = lineHeight > 10 ? lineHeight : this.props.fallBackLineHeight || 21
    }

    onShowMore(e) {
        const { onClickMore, preventShowMore } = this.props
        if (preventShowMore) {
            return
        }
        e.preventDefault()
        e.stopPropagation()
        this.setState({ showMore: true })

        onClickMore && onClickMore()
    }

    onShowLess(e) {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ showMore: false })
        setTimeout(() => {
            this.forceUpdate()
        }, 50)
    }

    formatLineBreaks(string) {
        if (typeof string === 'string') {
            const lines = string.split('\n')
            return lines.map((item, key) => {
                if (item.length > 0) {
                    return (
                        <span key={key}>
                            {item}
                            <br />
                        </span>
                    )
                } else if (lines[key + 1] && lines[key + 1].length) {
                    return (
                        <span key={key}>
                            <br />
                        </span>
                    )
                }
            })
        }

        return string
    }

    render() {
        const {
            className,
            text,
            maxLines = 2,
            buttonId,
            altMoreButton,
            lessButton,
            canShowMore = true,
            tagName = 'div',
            before,
            backgroundColor = '#fff',
            quoted = false,
            readMoreText = 'Lees meer',
            readLessText = 'Lees minder',
        } = this.props
        const { showMore } = this.state
        const hasOverflow = this.hasOverflow()
        const style = {
            maxHeight: showMore ? '100%' : this.lineHeight * maxLines,
            lineHeight: this.lineHeight && this.lineHeight + 'px',
            margin: 0,
            overflow: showMore ? 'visible' : 'hidden',
            position: 'relative',
        }
        const backgroundImage = `linear-gradient(90deg,hsla(0,0%,100%,0),${backgroundColor} 25%)`
        // eslint-disable-next-line react/no-unescaped-entities
        const quote = (quoted && <span className={css.quote}>"</span>) || null
        return (
            <Fragment>
                {React.createElement(
                    tagName,
                    {
                        ref: (el) => {
                            this.el = el
                        },
                        style,
                        className: [showMore ? '' : css.less, className ? className : ''].join(' '),
                    },
                    <Fragment>
                        {before}
                        {quote}
                        <span>{this.formatLineBreaks(text)}</span>
                        {(!hasOverflow || (hasOverflow && !showMore)) && quote}
                        {hasOverflow &&
                            !showMore &&
                            (canShowMore && !altMoreButton ? (
                                <span
                                    onClick={this.onShowMore}
                                    id={buttonId}
                                    className={[css.more, css.link].join(' ')}
                                    style={{ backgroundImage }}>
                                    {' '}
                                    {readMoreText}...
                                </span>
                            ) : (
                                <span
                                    className={[css.more, css.ellipsis].join(' ')}
                                    style={{ backgroundImage }}>
                                    {' '}
                                    ...{quote}
                                </span>
                            ))}
                        {showMore && lessButton && (
                            <button
                                id={buttonId}
                                className={[css.lessButton, altMoreButton ? style.alt : ''].join(
                                    ' '
                                )}
                                onClick={this.onShowLess}>
                                {altMoreButton ? (
                                    'Minder'
                                ) : (
                                    <div className={css.content}>
                                        {readLessText}{' '}
                                        <Icon
                                            name="expand_less"
                                            className="colorCta"
                                            fontSize={20}
                                        />
                                    </div>
                                )}
                            </button>
                        )}
                    </Fragment>
                )}
                {altMoreButton && hasOverflow && !showMore && (
                    <button id={buttonId} className={css.lessButton} onClick={this.onShowMore}>
                        Meer
                    </button>
                )}
            </Fragment>
        )
    }
}

MaxLines.propTypes = {
    lineHeight: PropTypes.number,
    maxLines: PropTypes.number,
    text: PropTypes.any,
}

export default MaxLines
