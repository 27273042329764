import LoadingDots from './LoadingDots'
import css from './loading.module.css'

interface Props {
    fullPage?: boolean
    size?: number
}

const Loading = ({ fullPage, size }: Props) => {
    return (
        <div>
            <div className={fullPage ? css.overlay : css.wrapper}>
                <div className={css.ldsEllipsis}>
                    <LoadingDots size={size} />
                </div>
            </div>
        </div>
    )
}

export default Loading
