import React, { CSSProperties, ReactNode } from 'react'
import Icon from './Icon'
import style from './preIcon.module.css'

interface Props {
    icon?: string
    children: ReactNode
    center?: boolean
    top?: boolean
    overflowFix?: boolean
    onTop?: boolean
    noWrap?: boolean
    tagName?: string
    outerStyle?: CSSProperties
    /**
     * @deprecated, use icon instead.
     */
    name?: string
    [x: string]: any
}

const PreIcon = ({
    icon,
    name,
    children,
    center,
    top,
    overflowFix,
    onTop,
    noWrap,
    tagName = 'div',
    outerStyle = {},
    ...rest
}: Props) => {
    const className = [
        style.root,
        onTop ? style.onTop : '',
        center ? style.center : '',
        top ? style.top : '',
        overflowFix ? style.overflowFix : '',
        noWrap ? style.noWrap : '',
        rest.className || '',
    ].join(' ')
    return React.createElement(
        tagName,
        {
            className,
            style: outerStyle,
            title: rest.title,
        },
        <>
            <Icon {...rest} name={icon || name} />
            {children}
        </>
    )
}

export default PreIcon
