import { ReactNode, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
// @ts-ignore
import { useTranslation } from 'react-i18next'
import { getCountryCode } from '../func'
import { Col, Row } from '../grid'
import { useIsTouchDevice, useWindowSize } from '../hooks'
import { Button, Icon } from '../ui'
import style from './waterMelonChatButton.module.scss'

export enum WaterMelonChatElementIds {
    WIDGET = 'watermelon-widget-wrapper', // this is the id of the widget iframe
    SCRIPT = 'wm-script',
    CHAT_BUTTON = 'wm-button',
    CHAT_BUTTON_FIXED = 'wm-fixed-button',
}

export enum WaterMelonChatButtonTypes {
    DEFAULT = 'default', // default button style
    FIXED = 'fixed', // fixed button on the bottom right of the screen
    LINK = 'link', // to add a chat link to a text (button link style)
    UNDERLINED = 'underlined', // to add a chat link to a text (button underlined style)
    BLOCK = 'block', // not button style, but integrated in a block component
}

interface Props {
    type?: WaterMelonChatButtonTypes
    minScreenWidth?: number
    minScreenWidthForIcon?: number
    alignLeft?: boolean
    hideOnTouchDevices?: boolean
    children?: ReactNode
}

/**
 * WaterMelonChatButton
 *
 * @param type - default, fixed, link, block
 * @param minScreenWidth - hide chat on smaller screens
 * @param minScreenWidthForIcon - hide icon to reduce the width of the button
 * @param alignLeft - align the widget to the left side of the screen
 * @param hideOnTouchDevices - hide chat on touch devices (prevent issues with overlapping elements)
 * @param children - text to display in the link button
 */

const WaterMelonChatButton = ({
    type = WaterMelonChatButtonTypes.DEFAULT,
    minScreenWidth = 0,
    minScreenWidthForIcon = 0,
    alignLeft = false,
    hideOnTouchDevices = false,
    children,
}: Props) => {
    const { t } = useTranslation()
    const { width } = useWindowSize()
    const isTouchDevice = useIsTouchDevice()
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [chatIsOpen, setChatIsOpen] = useState<boolean>(false)
    const [showChatButton, setShowChatButton] = useState<boolean>(false)
    const countryCode = getCountryCode().toUpperCase()
    const isSupply = window.location.pathname.includes('/pro')
    const waterMelonDataKeys: Record<string, Record<string, string[]>> = {
        NL: {
            demand: ['mQakX3T36KR', '10768'],
            supply: ['e6e78ESDb1P', '10769'],
        },
        BE: {
            demand: ['VZ6Ga8IGoPB', '10750'],
            supply: ['rVJ92AFQe9D', '10757'],
        },
        DE: {
            demand: ['ZDw1XehNEe7', '10752'],
            supply: ['8gq2EdC1kBo', '10753'],
        },
        ES: {
            demand: ['2Yg4q9H3Nez', '12545'],
            supply: ['M1p45nTEd61', '12544'],
        },
    }

    const loadChat = () => {
        const dataKeys = waterMelonDataKeys[countryCode][isSupply ? 'supply' : 'demand']

        // create load script
        const script = document.createElement('script')
        script.id = WaterMelonChatElementIds.SCRIPT
        script.async = true
        script.type = 'module'
        script.src = 'https://chatwidget-prod.web.app/embed/init.iife.js'
        script.setAttribute('data-watermelon-widget-id', dataKeys[0])
        script.setAttribute('data-watermelon-settings-id', dataKeys[1])
        script.onload = () => {
            setIsLoaded(true)
        }

        // add script to head
        document.head.appendChild(script)
    }

    const onToggleWidget = () => {
        if (window.watermelonWidget.isMinimized) {
            window.watermelonWidget.openWidget()
            setChatIsOpen(true)
        } else {
            window.watermelonWidget.closeWidget()
            setChatIsOpen(false)
        }
    }

    useEffect(() => {
        if (isLoaded && !(isTouchDevice && hideOnTouchDevices) && width >= minScreenWidth) {
            // show chat button
            !showChatButton && setShowChatButton(true)
        } else {
            // close chat widget
            chatIsOpen && onToggleWidget()

            // hide chat button
            showChatButton && setShowChatButton(false)
        }
    }, [isLoaded, width])

    useEffect(() => {
        loadChat()
    }, [])

    return (
        <>
            {alignLeft && (
                <Helmet>
                    <style type="text/css">
                        {`
                        #${WaterMelonChatElementIds.WIDGET} { 
                            right: unset;
                            left: var(--watermelon-yg-widget-horizontal-offset);
                         }
                    `}
                    </style>
                </Helmet>
            )}
            {showChatButton && (
                <>
                    {type === WaterMelonChatButtonTypes.LINK ? (
                        <Button name="link" disabled={!isLoaded} onClick={onToggleWidget}>
                            {children}
                        </Button>
                    ) : type === WaterMelonChatButtonTypes.UNDERLINED ? (
                        <Button name="underline" disabled={!isLoaded} onClick={onToggleWidget}>
                            {children}
                        </Button>
                    ) : (
                        <div
                            id={
                                type === WaterMelonChatButtonTypes.FIXED
                                    ? WaterMelonChatElementIds.CHAT_BUTTON_FIXED
                                    : WaterMelonChatElementIds.CHAT_BUTTON
                            }
                            className={[style.waterMelonChatButton, style[type]].join(' ')}
                            onClick={onToggleWidget}>
                            <Row middle="xs" className={style.row}>
                                {width >= minScreenWidthForIcon && (
                                    <Col x className={[style.col, style.chatIcon].join(' ')}>
                                        <Icon name="chat" fontSize={24} />
                                    </Col>
                                )}
                                <Col x className={style.col}>
                                    <span>{t('chat_button_questions')}</span>{' '}
                                    <span className={style.noWhiteSpaceWrap}>
                                        {t('chat_button_chat_with_us')}
                                    </span>
                                </Col>
                                {type === WaterMelonChatButtonTypes.FIXED && (
                                    <Col x className={[style.col, style.expandIcon].join(' ')}>
                                        <Icon
                                            name={chatIsOpen ? 'expand_more' : 'expand_less'}
                                            fontSize={28}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default WaterMelonChatButton
