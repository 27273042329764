import { Col, Row } from '@advanza/grid'
import { Button, Divider, Ellipsis, Icon } from '@advanza/ui'
import FaqEntity from 'components/faqs/FaqEntity'
import ExpandableEntity from 'components/FlexTable/ExpandableEntity'
import OrderButtonArrows from 'components/misc/OrderButtonArrows'
import TableList from 'components/TableList'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useAddFaq, useChangeFaqOrder, useFaqsList } from 'hooks/faqsHook'

const getDefinitions = () => {
    const items = {
        faq_id: { name: '#' },
        question: { name: 'question' },
        answer: { name: 'answer' },
        order: { name: 'order' },
    }

    return items
}

function renderRow(id, entities, showTopics, changeOrder, service) {
    const { faqs } = entities
    const entity = faqs[id]
    return {
        cols: Object.keys(getDefinitions()).map((key) => {
            switch (key) {
                case 'faq_id':
                    return typeof entity[key] === 'string' ? <Icon name="fiber_new" /> : entity[key]
                case 'answer':
                    return <Ellipsis style={{ maxWidth: 200 }}>{entity[key]}</Ellipsis>
                case 'faq_topic_id':
                    return entity.topic || 'default'
                case 'order':
                    return (
                        <OrderButtonArrows
                            clickUp={() => changeOrder(-1)}
                            clickDown={() => changeOrder(1)}
                        />
                    )
                default:
                    return entity[key]
            }
        }),
        id,
        expandable: (
            <ExpandableEntity>
                <FaqEntity id={id} showTopics={showTopics} service={service} />
            </ExpandableEntity>
        ),
        expanded: entity._open,
        expandOnClick: false,
        isLoading: entity._saving,
    }
}

const FaqsList = ({ filterId = 'all', options, pageSize = 100, service, showTopics = false }) => {
    const { filter = {}, currentPage, ...rest } = useFaqsList(filterId, { ...options, pageSize })
    const addFaq = useAddFaq(filterId, options)
    const definitions = getDefinitions()
    const { changeOrder } = useChangeFaqOrder(filterId)
    if (showTopics) {
        definitions.faq_topic_id = { name: 'topic' }
    }

    const ordered = currentPage.sort(
        (a, b) => rest.entities.faqs[a].order_value - rest.entities.faqs[b].order_value
    )
    const rows = {}
    ordered.forEach((id) => {
        const onChangeOrder = (modify) => changeOrder(ordered, id, modify)
        rows[id] = renderRow(id, rest.entities, showTopics, onChangeOrder, service)
    })

    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <TableList {...rest} filter={filter} rows={rows} definitions={definitions} />
            <Divider m />
            <Row end="xs">
                <Col x>
                    <Button name="text" onClick={addFaq}>
                        <Icon name="add" className="colorCta" style={{ marginTop: '-4px' }} /> Add
                        FAQ
                    </Button>
                </Col>
            </Row>
        </LoadBoxWrapper>
    )
}

export default FaqsList
