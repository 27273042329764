import { Link } from 'react-router-dom'
import style from './a.module.css'

const A = ({ className = '', children, external, ...rest }) => {
    const props = {
        className: style[className] || className || '',
        ...rest,
    }
    if (external) {
        return <a {...props}>{children}</a>
    } else {
        return <Link {...props}>{children}</Link>
    }
}

export default A
