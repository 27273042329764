import { CSSProperties, ReactElement } from 'react'
import style from './loadingDots.module.css'

interface Props {
    className?: string
    size?: string | number
    color?: string
    duration?: string
    style?: CSSProperties
    dotElement?: ReactElement
}

const LoadingDots = ({
    className,
    size,
    color,
    duration,
    style: inlineStyle,
    dotElement,
}: Props) => {
    const dotEl = dotElement || (
        <span
            style={{
                backgroundColor: color,
                animationDuration: duration,
            }}
        />
    )
    return (
        <span
            className={[style.root, className].join(' ')}
            // todo: solve mismatch between the csstype package in /shared and /trustoo_pros
            // @ts-ignore
            style={{ fontSize: size, ...(inlineStyle || {}) }}>
            {dotEl}
            {dotEl}
            {dotEl}
        </span>
    )
}

export default LoadingDots
