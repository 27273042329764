import { Col, Row } from '@advanza/grid'
import { Button, LinearLoader } from '@advanza/ui'
import { useState } from 'react'
import style from './../simpleModal.module.css'
import Modal from './Modal'

const SimpleModal = ({
    header = '',
    body = '',
    buttons = [],
    cancelFunc = undefined,
    styleName = '',
    className = '',
    isOpen = false,
    setIsOpen = (bool) => bool,
    setResult = (value) => value,
    isSaving = false,
    ...rest
}) => {
    const [isBusyInternal, setIsBusyInternal] = useState(false)
    const isBusyCombined = isSaving || isBusyInternal

    const closeModal = (result) => {
        setIsOpen(false)
        setResult(result)
    }

    const doFunc = (func = () => Promise.resolve(), close = true) => {
        setIsBusyInternal(true)
        const funcReturn = func()
        const promise = funcReturn instanceof Promise ? funcReturn : Promise.resolve(funcReturn)
        promise.then(
            (result) => {
                !result?.unmountedModal && setIsBusyInternal(false)
                close && closeModal(result)
            },
            (reject) => !reject?.unmountedModal && setIsBusyInternal(false)
        )
    }

    return (
        <Modal
            className={[style[styleName], className].join(' ')}
            classObj={style}
            open={isOpen}
            close={() => !isBusyCombined && doFunc(cancelFunc)}
            {...rest}>
            {isBusyCombined && <LinearLoader absolute />}
            {header && <h2 className={style.header}>{header}</h2>}
            {body && <div className={style.body}>{body}</div>}
            {buttons.length > 0 && (
                <Row
                    {...(buttons.length === 1 ? { end: 'xs' } : { between: 'xs' })}
                    middle="xs"
                    className={style.buttons}>
                    {buttons.map(({ text, func, name, close, disabled }, i) => (
                        <Col x key={i}>
                            <Button
                                name={name}
                                disabled={isBusyCombined || disabled}
                                onClick={() => doFunc(func, close)}>
                                {text}
                            </Button>
                        </Col>
                    ))}
                </Row>
            )}
        </Modal>
    )
}

export default SimpleModal

export const ActionModal = ({
    cancelText = 'Sluiten',
    cancelFunc = undefined,
    text = 'Ok',
    func = null,
    ...rest
}) => (
    <SimpleModal
        buttons={[
            { text: cancelText, func: cancelFunc, name: func ? 'bordered' : undefined },
            ...(func ? [{ text, func }] : []),
        ]}
        cancelFunc={cancelFunc}
        {...rest}
    />
)

export const useSimpleModal = (type = undefined) => {
    const [objWithResolve, setObjWithResolve] = useState(null)

    const setResult = (result) => {
        objWithResolve.resolve(result)
        setObjWithResolve(null)
    }

    const openModal = () => new Promise((resolve) => setObjWithResolve({ resolve }))

    const renderModal = (props) => {
        const allProps = { ...props, isOpen: !!objWithResolve, setResult }
        return type === 'ActionModal' ? (
            <ActionModal {...allProps} />
        ) : (
            <SimpleModal {...allProps} />
        )
    }

    return { openModal, renderModal }
}

export const useActionModal = () => useSimpleModal('ActionModal')
