import { Divider, HoverText, Icon, PreIcon } from '@advanza/ui'
import Card from 'components/Card'
import AutoRow from 'components/misc/AutoRow'
import NotesFilter from 'components/notes/NotesFilter'
import NotesList from 'components/notes/NotesList'
import Pagination from 'components/Pagination'
import ReclaimButtons from 'components/reclaims/ReclaimButtons'
import ReclaimHoverText from 'components/reclaims/ReclaimHoverText'
import ReclaimsFilters from 'components/reclaims/ReclaimsFilters'
import UndoReclaimButton from 'components/reclaims/UndoReclaimButton'
import TableList from 'components/TableList'
import A from 'components/ui/A'
import Balloon from 'components/ui/Balloon'
import FormattedTime from 'components/ui/FormattedTime'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { getDate } from 'date'
import format from 'date-fns/format'
import { useReclaimsList } from 'hooks/reclaimHooks'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import style from './ReclaimsList.module.css'

const definitions = {
    reclaim_id: { name: '#' },
    status: { name: 'status' },
    automated_check_status: { name: <Icon name="smart_toy" /> },
    firstThree: { name: <Icon name="whatshot" /> },
    prospect: { name: 'Prosp' },
    keyAccount: { name: 'Key' },
    businessName: { name: 'Name' },
    created: { name: 'Date' },
    leadName: { name: 'Name' },
    phoneNumber: { name: 'Phone' },
    matchDate: { name: 'Request date' },
    reason: { name: 'Reason' },
    nrReclaims: { name: '#rec' },
    nrReclaimsTotal: { name: '#recT' },
    reclaimPercentageTotal: { name: 'RecT%' },
    action: { name: 'Action' },
    notes: { name: 'Notes' },
}

function renderRow(id, entities, compact) {
    const { reclaims, notes } = entities
    const reclaim = reclaims[id]
    const providerUrl = `/service/providers/provider/${reclaim.providerId}/profile`
    const requestUrl = `/service/requests/request/${reclaim.requestId}`
    const filterIdAssociations = `reclaimNotes${id}`

    return {
        cols: Object.keys(definitions).map((key) => {
            switch (key) {
                case 'businessName':
                    return (
                        <A className="classic" to={providerUrl}>
                            {reclaim.businessName}
                        </A>
                    )
                case 'status':
                    return reclaim.accepted ? (
                        <HoverText trigger={<span style={{ color: 'green' }}>Accepted</span>}>
                            <div className={style.acceptedHover}>
                                <div>Reclaim accepted:</div>
                                <div>{format(getDate(reclaim.accepted), 'd-MMM-yyyy HH:mm')}</div>
                                <div>Uncharge date:</div>
                                <div>
                                    {format(getDate(reclaim.unChargedDate), 'd-MMM-yyyy HH:mm')}
                                    {reclaim.isFreeLeadReclaim && (
                                        <>
                                            <Divider s />
                                            <small>
                                                This lead was a free lead, so it was already
                                                uncharged.
                                                <br />
                                                The number of free leads has been increased to
                                                compensate.
                                            </small>
                                        </>
                                    )}
                                </div>
                                {reclaim.admin_comment && (
                                    <>
                                        <div>Admin comment:</div>
                                        <div>{reclaim.admin_comment}</div>
                                    </>
                                )}
                            </div>
                        </HoverText>
                    ) : reclaim.denied ? (
                        <HoverText trigger={<span style={{ color: 'red' }}>Rejected</span>}>
                            {format(getDate(reclaim.denied), 'd-MMM HH:mm')}
                            <br />
                            {reclaim.admin_comment}
                        </HoverText>
                    ) : (
                        <span style={{ color: 'orange' }}>Open</span>
                    )
                case 'firstThree':
                    return !reclaim.accepted && !reclaim.denied && reclaim.nrReclaimsTotal <= 3 ? (
                        <Balloon content="Within first 3 reclaims">
                            <Icon name="whatshot" />
                        </Balloon>
                    ) : (
                        ''
                    )
                case 'prospect':
                    return (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {['€€€€', '€€€€€'].includes(reclaim.prospect || '') && reclaim.prospect}
                        </span>
                    )
                case 'keyAccount':
                    return (
                        <Balloon content={reclaim.keyAccountDesc} style={{ fontSize: 20 }}>
                            {
                                { key_account: '🥇', silver_account: '🥈', bronze_account: '🥉' }[
                                    reclaim.keyAccount
                                ]
                            }
                        </Balloon>
                    )
                case 'created':
                    return (
                        <small>
                            <FormattedTime date={reclaim[key]} />
                        </small>
                    )
                case 'leadName':
                    return (
                        <A className="classic" to={requestUrl}>
                            {reclaim.firstName}
                        </A>
                    )
                case 'phoneNumber':
                    return reclaim.phoneNumber ? (
                        <span>
                            <Icon name="phone" />
                            {reclaim.phoneNumber}
                        </span>
                    ) : (
                        ''
                    )
                case 'matchDate':
                    return (
                        <small>
                            {format(
                                new Date(
                                    reclaim.phoneNumber ? reclaim['phoneClickDate'] : reclaim[key]
                                ),
                                'd-MMM-yyyy'
                            )}
                        </small>
                    )
                case 'reason':
                    return reclaim.extra_field ? (
                        <HoverText trigger={reclaim[key]}>{reclaim.extra_field}</HoverText>
                    ) : (
                        reclaim[key]
                    )
                case 'nrReclaims':
                    return (
                        <HoverText trigger={reclaim.nrReclaimsLast3Month}>
                            Nr reclaims last 3 months
                        </HoverText>
                    )
                case 'nrReclaimsTotal':
                    return (
                        <HoverText trigger={reclaim.nrReclaimsTotal}>
                            Total nr of reclaims
                        </HoverText>
                    )
                case 'reclaimPercentageTotal':
                    return (
                        <ReclaimHoverText
                            trigger={
                                reclaim.nrReclaimsTotal == 0
                                    ? '-'
                                    : Math.round(
                                          (reclaim.nrReclaimsTotal / reclaim.nrMatchesTotal) * 100,
                                          1
                                      ) + '%'
                            }
                            reclaim={reclaim}
                        />
                    )
                case 'action':
                    return <ReclaimButtons key={reclaim.reclaim_id} reclaim={reclaim} />
                case 'notes':
                    if (reclaim.notes.length) {
                        const note = notes[reclaim[key][reclaim[key].length - 1]].note
                        const previewLength = compact ? 10 : 24
                        if (note && note.length > previewLength) {
                            return (
                                <HoverText trigger={note.slice(0, previewLength) + '...'}>
                                    {note}
                                </HoverText>
                            )
                        }
                        return note
                    }
                    return ''
                case 'automated_check_status':
                    return (
                        reclaim[key] && (
                            <PreIcon icon="smart_toy" overflowFix>
                                {reclaim[key].replace(/([A-Z])/g, ' $1').toLowerCase()}
                            </PreIcon>
                        )
                    )
                default:
                    return reclaim[key]
            }
        }),
        expandable: (
            <Card expandableStyle>
                <AutoRow noWrap>
                    {(reclaim.accepted || reclaim.denied) && (
                        <UndoReclaimButton reclaim={reclaim} />
                    )}
                    {reclaim.extra_field && <span>{reclaim.extra_field}</span>}
                    <div style={{ width: '100%' }}>
                        <Card header="notes" icon="speaker_notes">
                            <NotesFilter
                                filterId={filterIdAssociations}
                                defaultOptions={{ reclaimIds: [id], pageSize: 3 }}
                                newEntityOptions={{ type: 'reclaims', foreign_key: id }}
                            />
                            <NotesList filterId={filterIdAssociations} />
                        </Card>
                    </div>
                </AutoRow>
            </Card>
        ),
        className: reclaim.automated_check_status === 'mailSent' && style.rowAutomatedCheckActive,
    }
}

const ReclaimsList = ({ filterId = 'all', defaultOptions = {}, ...r }) => {
    const { countries } = useSelector((state) => state.countries)
    const {
        filter = {},
        currentPage,
        selectable = false,
        changeFilter,
        pageNr,
        ...rest
    } = useReclaimsList(filterId, { ...defaultOptions, countries })

    useEffect(() => {
        changeFilter({ countries })
    }, [countries])

    const rows = {}
    const compact = !!r.compact
    currentPage.forEach((id) => {
        rows[id] = renderRow(id, rest.entities, compact)
    })

    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <AutoRow>
                <ReclaimsFilters {...r} filter={filter} changeFilter={changeFilter} />
                {rest.count && (
                    <Pagination
                        current={pageNr}
                        pageSize={parseInt(filter.pageSize)}
                        count={parseInt(rest.count)}
                        onPageChange={(page) => changeFilter({ page })}
                    />
                )}
                <ExportButton filter={filter} />
            </AutoRow>
            <TableList
                {...r}
                {...rest}
                selectable={selectable}
                filter={filter}
                rows={rows}
                definitions={definitions}
            />
        </LoadBoxWrapper>
    )
}

export default ReclaimsList

const ExportButton = ({ filter }) => {
    const exportUrl = `/api/office/reclaims/export/?${new URLSearchParams(filter).toString()}`
    return (
        <a href={exportUrl} target="_blank" rel="noreferrer">
            Export
        </a>
    )
}
