export function getCompanyBaseUrlOffice(countryCode: string = 'NL'): string {
    if (typeof window === 'undefined') return ''

    const hostnamePart =
        countryCode.toUpperCase() === 'NL'
            ? 'trustoo.nl'
            : `trustlocal.${countryCode.toLowerCase()}`
    const { hostname } = window.location

    if (hostname.startsWith('localhost') || hostname.startsWith('l.')) {
        return `http://l.${hostnamePart}:3000` // next app runs on port 3000 locally
    } else if (hostname.startsWith('test')) {
        return `https://test.${hostnamePart}`
    } else {
        return `https://${hostnamePart}`
    }
}
