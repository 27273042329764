import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function useCalendlyWindowEvents() {
    // add this hook only at page level to prevent duplicate window event listeners

    const dispatch = useDispatch()
    const { isLoaded, calendlyEventId } = useSelector((state) => state.providers.calendly)
    const isReschedule = Boolean(calendlyEventId)

    const isCalendlyEvent = (e) =>
        e.origin === 'https://calendly.com' &&
        e.data.event &&
        e.data.event.indexOf('calendly.') === 0

    const checkForUpdates = () => {
        // check when processing the calendly data is finished (via CalendlyJob)
        dispatch({ type: 'CALENDLY_CHECK_UPDATES' })
    }

    const onBookingFlowMessage = (e) => {
        /**
         * The embedded scheduling page notifies the parent window of important events during the booking flow
         * https://developer.calendly.com/api-docs/ZG9jOjI3ODM2MTAz-notifying-the-parent-window
         */
        if (isCalendlyEvent(e)) {
            if (e.data.event === 'calendly.event_scheduled' && !isReschedule) {
                // successfully booked a new meeting
                checkForUpdates()

                // there is an option to process the data: e.data.payload.event.uri and e.data.payload.invitee.uri
                // currently not needed, because the webhook subscription is already sending it to the database
            }

            if (e.data.event === 'calendly.date_and_time_selected' && isReschedule) {
                // reschudule doesn't fire the 'calendly.event_scheduled' event
                checkForUpdates()
            }
        }
    }

    useEffect(() => {
        if (isLoaded) {
            window.addEventListener('message', onBookingFlowMessage)
            return () => window.removeEventListener('message', onBookingFlowMessage)
        }
    }, [isLoaded, calendlyEventId])
}
