import { Col, Row } from '@advanza/grid'
import { Button, Divider, FixedWarningPopup, Icon } from '@advanza/ui'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useChangeEntity } from 'hooks/entityHooks'
import { deleteFaq, saveFaq } from 'hooks/faqsHook'
import { useFaqTopicsList } from 'hooks/faqTopicsHooks'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const fields = {
    question: { type: 'text', placeholder: 'question' },
    answer: { type: 'autoGrow', maxRows: 100, placeholder: 'answer' },
    visible: { type: 'boolean', msg: 'visible' },
}

const FaqEntity = ({ id, showTopics = false, service = null }) => {
    const country = useSelector((state) => state.countries.countries[0])
    const { entities, isFetching } = useFaqTopicsList('all', { country_code: country })
    const { faqTopics = {} } = entities
    if (showTopics) {
        fields.faq_topic_id = {
            type: 'select',
            msg: 'topic',
            className: 'select-min select-stealth',
            options: Object.keys(faqTopics).map((key) => {
                return { value: faqTopics[key].faq_topic_id, title: faqTopics[key].name } || {}
            }),
        }
    }
    const [toast, setToast] = useState({})
    const { entity, onSaveEntity, renderInput, onDeleteEntity } = useChangeEntity(
        {
            store: 'faqs',
            name: 'faqs',
            entityId: id,
            saveFunc: saveFaq,
            deleteFunc: deleteFaq,
        },
        fields
    )

    if (showTopics) {
        fields.faq_topic_id.placeholder = entity.faq_topic_id
    }
    const onSave = () =>
        onSaveEntity().then(
            () => {
                setToast({ id: Date.now(), message: 'Faq saved' })
            },
            (response) => {
                setToast({ isError: true, id: Date.now(), message: JSON.stringify(response) })
            }
        )

    const insertVariables = (str) => {
        const serviceUrl = (service && service.url) || 'service'
        const replace = [
            ['plaats', 'Voorhout'],
            ['Ort', 'Voorhout'],
            ['urlStart', `zuid-holland/voorhout/${serviceUrl}`],
        ]
        replace.forEach((item) => {
            const search = new RegExp(`{{${item[0]}}}`, 'g')
            str = str.replace(search, item[1])
        })

        return str
    }
    return (
        <LoadBoxWrapper isLoading={isFetching}>
            <div>
                {showTopics && renderInput('faq_topic_id')}
                <Divider />
                <Divider m />
                {renderInput('question')}
                <Divider m />
                <b>
                    Answer {`{{city}} | {{urlStart}}`}
                    <small>Use raw html, no markdown</small>
                </b>
                <Divider />
                {renderInput('answer')}

                <Divider m />
                <Row end="xs" middle="xs">
                    <Col x>{renderInput('visible')}</Col>
                    <Col x>
                        <button onClick={onDeleteEntity}>
                            <Icon name={entity._saving ? 'hourglass_empty' : 'delete_forever'} />
                        </button>
                    </Col>
                    <Col x>
                        <Button
                            type="text"
                            onClick={onSave}
                            disabled={!entity._isTouched || entity._saving}>
                            {entity._saving ? '..saving' : 'Save'}
                        </Button>
                    </Col>
                </Row>
            </div>
            {toast.isError && <div style={{ color: 'red' }}>not saved</div>}
            <FixedWarningPopup red={toast.isError} show={toast.id}>
                {toast.message}
            </FixedWarningPopup>
        </LoadBoxWrapper>
    )
}

export default FaqEntity
