import { PreIcon } from '@advanza/ui'
import style from './../toggle.module.css'

const Toggle = ({
    name,
    value: isChecked,
    disabled = false,
    className = '',
    legend = false,
    inline = false,
    placeholder = '',
    showText = true,
    textTrue = 'Visible',
    textFalse = 'Hidden',
    onChange,
}) => {
    const onChangeToggle = (e) => {
        const value = e.target.value === '1' ? true : false
        return onChange({ target: { name, value } })
    }

    return (
        <div className={[style.toggle, inline ? style.inline : '', className].join(' ')}>
            {legend && <div className={style.legend}>{placeholder}</div>}
            <label className={isChecked ? style.toggleOn : style.toggleOff}>
                <input
                    type="checkbox"
                    disabled={disabled}
                    value={isChecked ? 0 : 1}
                    name={name}
                    onChange={onChangeToggle}
                />
                <PreIcon name={isChecked ? 'toggle_on' : 'toggle_off'}>
                    {showText && (isChecked ? textTrue : textFalse)}
                </PreIcon>
            </label>
        </div>
    )
}

export default Toggle
